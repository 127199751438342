:root {
  --color-primary: #4950ff;
  --color-gray10: #fafafd;
  --color-gray20: #f3f3f7;
  --color-gray50: #d7d8e4;
  --color-gray300: #848da5;
  --color-gray500: #4f5968;
  --color-gray700: #1b1d21;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("/fonts/nunito-sans-v6-latin-600.woff2") format("woff2"), url("/fonts/nunito-sans-v6-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("/fonts/nunito-sans-v6-latin-900.woff2") format("woff2"), url("/fonts/nunito-sans-v6-latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
html {
  background: white;
  color: var(--color-gray700);
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-weight: 600;
}

body {
  margin: 0 auto;
  padding: 5rem 3rem;
  max-width: 1200px;
}
@media (max-width: 599px) {
  body {
    padding: 3rem 1rem;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-weight: 900;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0;
}
@media (max-width: 599px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  margin: 60px 0 30px 0;
}

p {
  margin: 1em 0;
  line-height: 1.5;
}

a {
  color: inherit;
  display: inline-block;
  transition: transform 300ms ease-out;
}

a:hover {
  color: var(--color-primary);
  transform: translateY(-2px);
}

a:active {
  transform: translateY(0);
}

mark {
  padding: 0.1em 0.5em;
  margin: 0 0.2em;
  border-radius: 6px;
  white-space: nowrap;
  font-weight: bold;
}

hr {
  border: none;
  width: 100%;
  background-color: var(--color-gray20);
  height: 1px;
  margin: 1.5em 0;
}

.pronunciation {
  color: var(--color-gray300);
  margin-top: 0;
}

ul {
  list-style: none;
  padding-inline-start: 2em;
  line-height: 1.75;
}

li {
  position: relative;
  padding-left: 0.75em;
}

li:not(:last-child) {
  margin-bottom: 1.25em;
}

li > img {
  position: absolute;
  left: -2em;
  top: 50%;
  transform: translateY(-50%);
  height: 2em;
  width: 2em;
  vertical-align: middle;
  background: var(--color-gray20);
  border-radius: 50%;
  padding: 0.4em;
}

.color-primary {
  color: var(--color-primary);
}

mark > a:hover {
  color: inherit;
  transform: none;
}

mark.badgers {
  background-color: #c5050c;
  color: white;
  border-bottom: 3px solid #800005;
}

mark.sf {
  background-color: #e62617;
  color: white;
  border-bottom: 3px solid #900511;
}

mark.sentry {
  background-color: #584674;
  color: white;
  border-bottom: 3px solid #e1567c;
}

.underline {
  margin-top: 4px;
  width: 50px;
  height: 4px;
  background-color: var(--color-primary);
}

.projects-container {
  max-width: 1000px;
  margin-top: 2rem;
}

.featured-projects > :not(:first-child) {
  margin-top: 2rem;
}

.other-projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin-top: 2rem;
}
@media (max-width: 599px) {
  .other-projects {
    display: block;
  }
  .other-projects > :not(:last-child) {
    margin-bottom: 2rem;
  }
}

.card {
  background-color: var(--color-gray20);
  border-radius: 1.5rem;
  padding: 1.5rem 2rem;
  max-width: 1000px;
}
.card h3 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin: 0 0.5rem 0 0;
}
.card h3 img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.75rem;
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: 0 0.1rem 0.25rem rgba(27, 29, 33, 0.05);
}
.card p {
  font-size: 0.9rem;
  max-width: 50rem;
  margin: 1rem 0 0 0;
}
.card .screenshot {
  height: 100px;
  box-shadow: rgba(27, 29, 33, 0.1) 0px 4px 12px;
  width: 100px;
  border-radius: 4px;
}
.card--seddy {
  background-color: #d2def8;
}
.card--castclips {
  background-color: #ffe9db;
}
.card--phyloquiz {
  background-color: #e7f7ec;
}

.featured-links {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.featured-links > :not(:last-child) {
  margin-right: 1rem;
}

.featured-images-container {
  margin: 2rem -5rem 0 -5rem;
  width: calc(100% + 10rem);
}
@media (max-width: 599px) {
  .featured-images-container {
    margin: 2rem -3rem 0 -3rem;
    width: 100vw;
    overflow-x: scroll;
    padding: 0.1rem 0 0.5rem 0;
  }
}

.featured-images {
  display: grid;
  grid-template-columns: 65% auto auto;
  grid-template-rows: auto auto;
  grid-gap: 1rem;
  align-items: space-between;
  width: 100%;
}
.featured-images > video,
.featured-images picture,
.featured-images img {
  border-radius: 0.25rem;
  box-shadow: rgba(27, 29, 33, 0.05) 0 0 0 1px, rgba(27, 29, 33, 0.1) 0.3rem 0.3rem 0.5rem -0.1rem;
  background-color: var(--color-gray10);
}
.featured-images video {
  grid-column: 1;
  grid-row: 1/span 2;
  width: 100%;
}
.featured-images img,
.featured-images video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.featured-images .featured-image-1 {
  grid-column: 2/span 2;
  grid-row: 1;
}
.featured-images .featured-image-2 {
  grid-column: 2;
  grid-row: 2;
}
.featured-images .featured-image-3 {
  grid-column: 3;
  grid-row: 2;
}
@media (max-width: 599px) {
  .featured-images {
    display: flex;
    width: auto;
    height: 14rem;
  }
  .featured-images::before, .featured-images::after {
    width: 1rem;
    height: 1rem;
    content: "";
    display: block;
    flex-shrink: 0;
  }
  .featured-images > video,
.featured-images picture,
.featured-images img {
    display: block;
    height: 100%;
    width: auto;
    min-width: 0;
    object-fit: contain;
    flex-shrink: 0;
  }
}

.card-description {
  min-height: 3rem;
}

.card-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 1rem;
}
.card-footer > :first-child {
  padding-right: 1rem;
}

.card-split-lr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-split-lr > :first-child {
  padding-right: 1rem;
}

.card-footer-info {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  line-height: 1;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
}
.card-footer-info > :not(:last-child) {
  margin-bottom: 0.5em;
}
.card-footer-info .icon-text {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-text {
  display: flex;
  align-items: center;
  fill: var(--color-gray300);
}
.icon-text img,
.icon-text svg {
  margin-right: 0.5rem;
  width: 1.25em;
  height: 1.25em;
}

.contact-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: var(--color-gray700);
}

.contact-container > :not(:last-child) {
  margin-right: 1.5em;
}

.social {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  padding: 1rem 1.25rem 1rem 0.5rem;
  border-radius: 0.5rem;
  height: 2.6rem;
  font-weight: bold;
  text-decoration: none;
}
.social.github {
  background-color: #333;
  color: white;
  border-bottom: 4px solid black;
}
.social.linkedin {
  background-color: #0077b5;
  border-bottom: 4px solid #0b4971;
  color: white;
}
.social > img {
  margin-right: 0.5em;
  height: 1.5rem;
  width: 1.5rem;
  background-color: white;
  border-radius: 0.75rem;
  padding: 0.1rem;
}

.icon-list {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.icon-list :not(:last-child) {
  margin-right: 0.5em;
}
.icon-list img,
.icon-list svg {
  height: 1.5em;
  width: 1.5em;
}